<template>
  <div class="d-flex flex-row-reverse bg-accent">
    <div v-if="loading" class="overlay">
      <clip-loader class="loading"></clip-loader>
    </div>
    <explore-dashboard-sidebar
      :key="sidebarKey"
      @showExploreChart="updateChart"
    />
    <div class="col-md-9 bg-accent min-vh-100 p-5">
      <dashboard-header :has-back="false" />
      <span class="d-flex">
        <span
          @click="accessClicked"
          :class="[
            isActive === 'access'
              ? 'is-active-bordered'
              : 'is-inactive-bordered',
            'px-3 py-2 font-sm fw-normal pointer-no-bg text-center',
          ]"
        >
          Access
        </span>
        <span
          @click="qualityClicked"
          :class="[
            isActive === 'quality'
              ? 'is-active-bordered'
              : 'is-inactive-bordered',
            'px-3 py-2 font-sm fw-normal pointer-no-bg text-center',
          ]"
        >
          Quality
        </span>
      </span>

      <explore-dynamic-chart :key="dynamicChartKey" />
    </div>
  </div>
</template>

<script>
import ExploreDashboardSidebar from "@/components/dashboardPage/ExploreDashboardSidebar";
import DashboardHeader from "@/components/dashboardPage/DashboardHeader";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import { computed, onMounted, ref, watch } from "vue";
import ExploreDynamicChart from "@/components/dashboardPage/explore/ExploreDynamicChart";
import { useStore } from "vuex";

export default {
  name: "ExploreDashboard",
  components: {
    DashboardHeader,
    ExploreDashboardSidebar,
    ExploreDynamicChart,
    ClipLoader,
  },
  setup() {
    const store = useStore();
    const loading = computed(() => store.state.explore.loading);
    const metadata = computed(() => store.state.explore.metadata);
    const isActive = ref("");
    const dynamicChartKey = ref(0);
    const sidebarKey = ref(0);

    onMounted(() => {
      isActive.value = store.state.explore.activeTab;
      store.dispatch("fetchExploreMetadata");
    });

    watch(
      () => metadata.value,
      () => {
        if (metadata.value !== null) {
          sidebarKey.value++;
        }
      }
    );

    const updateChart = (options) => {
      // console.log(options, ">>>>");

      store.dispatch("fetchChartData", options).then(() => {
        dynamicChartKey.value++;
      });
    };

    const accessClicked = () => {
      isActive.value = "access";
      store.commit("setChartData", null);
      store.commit("setActiveTab", "access");
      store.dispatch("fetchExploreMetadata", 1).then(() => {
        sidebarKey.value++;
      });
    };

    const qualityClicked = () => {
      isActive.value = "quality";
      store.commit("setChartData", null);
      store.commit("setActiveTab", "quality");
      store.dispatch("fetchExploreMetadata", 2).then(() => {
        sidebarKey.value++;
      });
    };

    return {
      loading,
      isActive,
      dynamicChartKey,
      sidebarKey,

      accessClicked,
      qualityClicked,
      updateChart,
    };
  },
};
</script>
