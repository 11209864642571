<template>
  <div class="dashboard-sb col-md-3 position-fixed top-0 start-0 pl-4">
    <div class="d-flex flex-column vh-100 mt-4">
      <div class="d-flex flex-column mt-4 ms-4">
        <label class="mb-2 fw-medium font-sm">List Of Tables</label>
        <span class="dropdown-bg dropdown-width">
          <a-select
            :value="selectedTable"
            placeholder="Select LGA"
            style="width: 90%"
            size="small"
            :options="
              tablesList.map((table) => ({
                value: lodash.capitalize(table.replaceAll('_', ' ')),
              }))
            "
            @change="tableChanged"
          />
        </span>
      </div>

      <div class="d-flex flex-column mt-3 ms-4">
        <label class="mb-2 fw-medium font-sm">Attributes</label>
        <span class="dropdown-bg dropdown-width">
          <a-select
            :value="selectedAttributes"
            placeholder="Select Attributes"
            style="width: 90%"
            mode="multiple"
            size="small"
            :options="
              attributes.map((item) => ({
                value: lodash.capitalize(item.replaceAll('_', ' ')),
              }))
            "
            @change="attributesChanged"
          />
        </span>
      </div>
      <span class="ms-4 mt-1 font-tiny text-info">
        Please select 2 attributes!
      </span>

      <div class="d-flex flex-column mt-3 mb-2 ms-4">
        <label class="mb-2 fw-medium font-sm">Group By</label>
        <span class="dropdown-bg dropdown-width">
          <a-select
            :value="groupBy"
            placeholder="Select attribute"
            style="width: 90%"
            size="small"
            :options="
              groupByOptions.map((item) => ({
                value: lodash.capitalize(item.replaceAll('_', ' ')),
              }))
            "
            @change="groupByChanged"
          />
        </span>
      </div>

      <div class="d-flex flex-column mt-3 mb-2 ms-4">
        <label class="mb-2 fw-medium font-sm">Output Indicator</label>
        <span class="dropdown-bg dropdown-width">
          <a-select
            :value="indicator"
            placeholder="Select indicator"
            style="width: 90%"
            size="small"
            :options="
              indicators.map((item) => ({
                value: lodash.capitalize(item.replaceAll('_', ' ')),
              }))
            "
            @change="indicatorChanged"
          />
        </span>
      </div>

      <div class="ms-4 py-4" @click="showExploreChart">
        <button
          class="btn btn-md btn-width"
          :class="[canExplore ? 'btn-primary' : 'disabled']"
          @click="showExploreChart"
        >
          Explore
        </button>
      </div>

      <!-- <div class="mt-auto ms-4 mb-4 py-4">
        <button
          class="btn btn-md btn-width d-flex justify-content-center"
          :class="[canExplore ? 'btn-primary' : 'disabled']"
        >
          <span
            class="material-icons-outlined font-lg me-1"
            :class="[canExplore ? 'text-white' : 'text-dark']"
          >
            file_download
          </span>
          Download
        </button>
      </div> -->
    </div>
  </div>
</template>

<script>
import { Select } from "ant-design-vue";
import { computed, ref } from "vue";
import { useStore } from "vuex";
import lodash from "lodash";

export default {
  name: "ExploreDashboardSidebar",
  emits: ["showExploreChart"],
  components: {
    "a-select": Select,
  },
  setup(props, { emit }) {
    const store = useStore();
    const metadata = computed(() => store.state.explore.metadata);
    const tablesList = ref([]);
    const data = ref([]);

    if (metadata.value !== null) {
      data.value = Object.entries(metadata.value);
      tablesList.value = data.value
        .map((table) => table[0])
        .filter((item) => item != "table");
    }

    const attributes = ref([]);
    const indicators = ref([]);
    const style =
      "border-radius: 4px !important; padding-top: 5px; border: none; background: #f4f5fc !important;";

    const selectedTable = ref(undefined);
    const selectedAttributes = ref([]);
    const groupByOptions = ref([]);
    const groupBy = ref(undefined);
    const indicator = ref(undefined);

    const tableOption = ref("");
    const attributesOption = ref([]);
    const groupByOption = ref("");
    const indicatorOption = ref("");

    const tableChanged = (value) => {
      let table = value.toLowerCase().replaceAll(" ", "_");
      selectedAttributes.value = [];
      groupByOptions.value = [];
      indicators.value = [];

      data.value.forEach((key) => {
        if (key[0] === table) {
          let param = Object.values(key[1]);

          tableOption.value = param[2];
          attributes.value = Object.values(param[0]);
          indicators.value = Object.values(param[1]);
        }
      });
    };

    const attributesChanged = (value) => {
      groupBy.value = "";
      groupByOptions.value = value;
      selectedAttributes.value = value.map((item) =>
        item.toLowerCase().replaceAll(" ", "_")
      );
    };

    const groupByChanged = (value) => {
      groupBy.value = value.toLowerCase().replaceAll(" ", "_");
    };

    const indicatorChanged = (value) => {
      indicatorOption.value = value.toLowerCase().replaceAll(" ", "_");
    };

    const canExplore = computed(
      () =>
        tableOption.value !== "" &&
        attributesOption.value.length > 1 &&
        groupByOption.value !== "" &&
        indicatorOption.value !== ""
    );

    const showExploreChart = () => {
      console.log("clivked");
      const data = [
        tableOption.value,
        Object.values(selectedAttributes.value).join(","),
        groupBy.value,
        indicatorOption.value,
      ];
      // console.log(data, "///////");

      emit("showExploreChart", data);
    };

    return {
      selectedTable,
      selectedAttributes,
      groupBy,
      indicator,

      style,
      tablesList,
      attributes,
      indicators,
      groupByOptions,
      canExplore,

      lodash,

      tableChanged,
      attributesChanged,
      groupByChanged,
      indicatorChanged,

      showExploreChart,
    };
  },
};
</script>

<style scoped>
.btn-width {
  width: 90%;
}
</style>
