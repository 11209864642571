<template>
  <div class="d-flex flex-column">
    <!-- <div class="d-flex mt-3">
      <div class="col">
        <div class="percentage-container bg-white radius-full me-2">
          <Progress :stroke-color="{'0%': 'rgba(0,204,242,0.7)', '100%': '#00CCF2FF'}" :strokeWidth="10" :width="80"
                    :height="80" type="circle" :percent="underage"/>
          <div class="d-flex flex-column ms-3">
            <span class="font-sm fw-normal">Percentage of underage pupils</span>
            <span class="d-flex align-items-end">
              <span class="font-big fw-bold">{{ underage }}%</span>
              <span class="font-tiny fw-light ms-3 mb-2">{{ increase }}%</span>
            </span>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="percentage-container bg-white radius-full ms-2">
          <Progress :stroke-color="{'0%': 'rgba(249,136,16,0.7)', '100%': '#f98810'}" :strokeWidth="10" :width="80"
                    :height="80" type="circle" :percent="25"/>
          <div class="d-flex flex-column ms-3">
            <span class="font-sm fw-normal">Percentage of overage pupils</span>
            <span class="d-flex align-items-end">
              <span class="font-big fw-bold">{{ overage }}</span>
              <span class="font-tiny fw-light ms-3 mb-2">{{ decrease }}</span>
            </span>
          </div>
        </div>
      </div>
    </div> -->

    <access-chart-by-level :level="level" />
  </div>
</template>

<script>
import { Progress } from "ant-design-vue";
import AccessChartByLevel from "@/components/dashboardPage/main/access/AccessChartByLevel";
import { useStore } from "vuex";
import { computed } from "@vue/runtime-dom";

export default {
  name: "Access",
  components: {
    Progress,
    AccessChartByLevel,
  },
  setup() {
    const store = useStore();
    const loading = computed(() => store.state.analysis.loading);

    return {
      loading,
    };
  },
  data() {
    return {
      level: "pre-primary",
      underage: 65,
      overage: 25,
      increase: "+3% this term",
      decrease: "-3% this term",
      isActive: "pre-primary",
      screens: {
        prePrimary: true,
        primary: false,
        junior: false,
        senior: false,
        nmec: false,
        explore: false,
      },
    };
  },
};
</script>

<style scoped></style>
