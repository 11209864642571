<template>
  <div class="d-flex flex-row-reverse bg-accent">
    <div v-if="loading" class="overlay">
      <clip-loader class="loading"></clip-loader>
    </div>
    <ASC-dashboard-sidebar
      :manageSchool="screens.schoolManagement"
      :dashboard="screens.dashboardSetting"
      @showDbSetting="showDashboardSetting"
      @showSchoolManagement="showSchoolManagement"
    ></ASC-dashboard-sidebar>

    <div class="col-md-9 main-content px-5 pt-4 align-self-end">
      <dashboard-header
        :hasBack="hasBack"
        @goBack="goBack"
        @userDashboard="gotoUserDashboard"
        @schoolDashboard="gotoSchoolDashboard"
        @ascDashboard="gotoASCDashboard"
        @userProfile="showProfile"
        @editUser="editSchoolProfile"
        @accountSetting="showAccountSetting"
        @dashboardSetting="showDashboardSetting"
      />

      <Asc-management
        v-if="screens.ascManagement"
        @showASCPlanning="showASCPlanning"
        @showLGAValidation="showLGAValidation"
        @showStateValidation="showStateValidation"
        @collectionPrompt="showCollectionOptionPrompt"
        @moecollectionReviewPrompt="showmoecollectionReviewPrompt"
      />

      <ASCPlanning v-if="screens.planning" />

      <LGAValidation v-if="screens.lgaValidation" />

      <state-validation
        v-if="screens.stateValidation"
        @showLGA="showLGASchoolsTable"
      />

      <LGASchoolsTable v-if="screens.lgaShools" />

      <a-modal
        v-model:visible="visible"
        title="Data Collection Mode"
        :confirm-loading="loading"
        @ok="proceedToNewEntry"
      >
        <template #footer>
          <a-button key="update" @click="updateEntry">Update Entry</a-button>
          <a-button key="submit" type="primary" @click="proceedToNewEntry"
            >New Entry</a-button
          >
        </template>
        <p>
          Click the New Entry button for new entry and the Update Entry button
          to edit/update existing entry.
        </p>
      </a-modal>
    </div>
  </div>
</template>

<script>
import DashboardHeader from "@/components/dashboardPage/DashboardHeader.vue";
import { Modal, Button } from "ant-design-vue";
import AscManagement from "@/components/dashboardPage/school/ASCDashboard/questionnaire/AscManagement.vue";
import ASCDashboardSidebar from "@/components/dashboardPage/school/ASCDashboard/questionnaire/ASCDashboardSidebar.vue";
import dashboardMixinVue from "@/helpers/mixins/dashboardMixin.vue";
import ASCPlanning from "@/components/dashboardPage/school/ASCDashboard/ASCPlanning.vue";
import LGAValidation from "@/components/dashboardPage/school/ASCDashboard/LGAValidation.vue";
import StateValidation from "@/components/dashboardPage/school/ASCDashboard/StateValidation.vue";
import { resetAll } from "@/helpers/utils/helper_functions";
import LGASchoolsTable from "@/components/dashboardPage/school/ASCDashboard/LGASchoolsTable.vue";
import router from "@/router";
import { mapActions } from "vuex";
import Roles from "@/authorities/roles";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";

const officerRoles = [
  Roles.smoe_emis_lga_officer,
  Roles.smoe_subeb_lga_officer,
  Roles.smoe_emis_state_officer,
  Roles.smoe_subeb_state_officer,
];

export default {
  name: "AscDashboard",
  mixins: [dashboardMixinVue],
  components: {
    DashboardHeader,
    ASCDashboardSidebar,
    AscManagement,
    ASCPlanning,
    LGAValidation,
    StateValidation,
    LGASchoolsTable,
    "a-button": Button,
    "a-modal": Modal,
    ClipLoader,
  },
  data() {
    return {
      hasBack: true,
      visible: false,
      screens: {
        profile: false,
        accountSetting: false,
        dashboardSetting: false,
        schoolManagement: true,
        createSchool: false,
        schoolTable: false,
        edit: false,
        draftTable: false,
        planning: false,
        lgaValidation: false,
        stateValidation: false,
        ascManagement: true,
        lgaShools: false,
      },
    };
  },
  mounted() {
    if (!this.isSupervisor && !this.isOfficer) {
      this.fetchUserSchool();
    }
    // console.log(this.ownSchool.id)
    // if(this.fetchMySchool) {
    //   this.$store.dispatch('checkTerms', this.$store.state.school.ownSchool.id)
    // }
    console.log(this.$store.state.auth.user.schoolId, "<<<<<<<<");

    this.fetchMySchool(this.$store.state.auth.user.schoolId).then(() => {
      if (
        this.$store.state.auth.user.role !== "ROLE_MOE_ADMIN" &&
        this.$store.state.auth.user.role !== "ROLE_SMOE_EMIS_LGA_OFFICER"
      ) {
        this.$store
          .dispatch("checkTerms", this.$store.state.auth.user.schoolId)
          .then(() => {
            if (this.updateMessage.status == "error") {
              this.$notification.open({
                type: this.updateMessage.status,
                message: this.updateMessage.message,
              });
            }
          });
      }
    });
  },
  computed: {
    loading() {
      return this.$store.state.asc.loading;
    },
    updateMessage() {
      return this.$store.state.questionnaireService.updateMessage;
    },
    ownSchool() {
      return this.$store.state.school.ownSchool;
    },
    authUserRole() {
      return this.$store.getters.loginUser.role;
    },
    isSupervisor() {
      return this.authUserRole === Roles.supervisor;
    },
    isOfficer() {
      return officerRoles.includes(this.authUserRole);
    },
  },
  methods: {
    ...mapActions(["fetchMySchool"]),

    fetchUserSchool() {
      this.fetchMySchool(this.$store.state.auth.user.schoolId);
    },

    showProfile() {
      resetAll(this.screens);
      this.profile = true;
    },
    showAccountSetting() {
      resetAll(this.screens);
      this.accountSetting = true;
    },
    showDashboardSetting() {
      resetAll(this.screens);
      this.dashboardSetting = true;
    },
    showSchoolManagement() {
      resetAll(this.screens);
      this.schoolManagement = true;
    },
    showCreateSchool() {
      resetAll(this.screens);
      this.createSchool = true;
    },
    showSchoolsTable() {
      resetAll(this.screens);
      this.schoolTable = true;
    },
    editSchoolProfile() {
      this.showCreateSchool();
      this.edit = true;
    },
    showSchoolDraftTable() {
      resetAll(this.screens);
      this.draftTable = true;
    },
    showASCPlanning() {
      resetAll(this.screens);
      this.screens.planning = true;
    },
    showLGAValidation() {
      resetAll(this.screens);
      this.screens.lgaValidation = true;
    },
    showLGASchoolsTable([id, name]) {
      console.log(`${id} ${name}`);
      resetAll(this.screens);
      this.screens.lgaShools = true;
    },
    showStateValidation() {
      resetAll(this.screens);
      this.screens.stateValidation = true;
    },
    showCollectionOptionPrompt() {
      this.visible = true;
    },
    showmoecollectionReviewPrompt() {
      router.push("/ascDashboard/collectionUpdate");
    },
    updateEntry() {
      this.visible = false;
      router.push("/ascDashboard/collectionUpdate");
    },
    proceedToNewEntry() {
      this.visible = false;
      router.push("/ascDashboard/dataCollection");
    },
  },
};
</script>
<style lang="scss">
@import "../../style/variable";

.active {
  color: $blue-dm;
}
</style>
