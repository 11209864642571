<template>
  <div class="d-flex flex-row-reverse bg-accent">
    <div v-if="loading" class="overlay">
      <clip-loader class="loading"></clip-loader>
    </div>
    <div v-if="termsLoading" class="overlay">
      <clip-loader class="loading"></clip-loader>
    </div>
    <asc-data-collection-sidebar
      :displaySidebar="showSidebar"
      @showIdentification="showSchoolIdentification"
      @showCharacteristics="showCharacteristics"
      @showEnrolment="showEnrolment"
      @showStaff="showStaff"
      @showClassroom="showClassroom"
      @showFacilities="showFacilities"
      @showSubject="showSubject"
      @showBook="showBook"
      @showReview="showReview"
      @showTeacher="showQualification"
      @showFamily="showFamily"
      @showAttestation="showAttestation"
      :activeScreen="screen"
      :introScreen="screen.introScreen"
      :schoolIdentification="screen.schoolIdentification"
    />

    <div class="col-md-9 main-content px-5 pt-4 align-self-end">
      <dashboard-header
        :hasBack="hasBack"
        @goBack="handleGoBack"
        @userDashboard="gotoUserDashboard"
        @schoolDashboard="gotoSchoolDashboard"
        @ascDashboard="gotoASCDashboard"
        @userProfile="showProfile"
        @accountSetting="showAccountSetting"
        @dashboardSetting="showDashboardSetting"
      />

      <!-- IF THE AUTH USER IS AN OFFICER -->
      <schools-submitted-questionaires-table
        v-if="isOfficer && screen.submittedQuestionaire"
        @viewSubmittedQuestionaire="showSubmissionReview"
      />

      <!-- OTHERWISE, PROCEED NORMALLY -->
      <asc-school-intro
        v-else-if="screen.introScreen"
        @showOption="setTerms()"
      />

      <submission-review
        v-if="screen.submissionReview"
        :institutionId="institutionId"
      />

      <questionnaire-option
        v-if="questionnaireOption"
        @nmec="gotoNMEC"
        @other="gotoOther"
      />

      <school-identification
        v-if="screen.schoolIdentification"
        @saveIdentification="saveIdentification"
      />

      <school-characteristics
        v-if="screen.characteristics"
        @saveCharacteristics="saveCharacteristics"
      />

      <school-enrolment
        v-if="screen.enrolment"
        @saveEnrolment="saveEnrolment"
      />

      <school-staff
        :key="staffKey"
        @reload="staffKey++"
        v-if="screen.staff"
        @continue="saveStaff"
      />

      <classroom @saveClassroom="saveClassroom" v-if="screen.classroom" />

      <facilities @saveFacilities="saveFacilities" v-if="screen.facilities" />

      <subject v-if="screen.subject" @continue="saveSubject" />

      <book v-if="screen.book" @saveBook="saveBook" />

      <teachers-qualification
        :key="genKey"
        @reload="genKey++"
        @continueNextSection="saveQualification"
        v-if="screen.qualification"
      />

      <family-life v-if="screen.family" />

      <attestation v-if="screen.attestation" @showReview="showReview" />

      <review-questionnaire v-if="screen.review" />
    </div>
  </div>
</template>

<script>
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import dashboardMixinVue from "@/helpers/mixins/dashboardMixin";
import AscDataCollectionSidebar from "@/components/dashboardPage/school/ASCDashboard/questionnaire/AscDataCollectionSidebar";
import AscSchoolIntro from "@/components/dashboardPage/school/ASCDashboard/questionnaire/AscSchoolIntro";
import SchoolIdentification from "@/components/dashboardPage/school/ASCDashboard/questionnaire/SchoolIdentification";
import DashboardHeader from "@/components/dashboardPage/DashboardHeader";
import dataCollectionMixin from "@/helpers/mixins/dataCollectionMixin";
import { resetAll } from "@/helpers/utils/helper_functions";
import SchoolCharacteristics from "@/components/dashboardPage/school/ASCDashboard/questionnaire/SchoolCharacteristics";
import SchoolEnrolment from "@/components/dashboardPage/school/ASCDashboard/questionnaire/SchoolEnrolment";
import SchoolStaff from "@/components/dashboardPage/school/ASCDashboard/questionnaire/SchoolStaff";
import Classroom from "@/components/dashboardPage/school/ASCDashboard/questionnaire/Classroom";
import Facilities from "@/components/dashboardPage/school/ASCDashboard/questionnaire/Facilities";
import Subject from "@/components/dashboardPage/school/ASCDashboard/questionnaire/Subject";
import Book from "@/components/dashboardPage/school/ASCDashboard/questionnaire/Book";
import FamilyLife from "@/components/dashboardPage/school/ASCDashboard/questionnaire/FamilyLife";
import TeachersQualification from "@/components/dashboardPage/school/ASCDashboard/questionnaire/TeachersQualification";
import QuestionnaireOption from "@/components/dashboardPage/school/ASCDashboard/questionnaire/QuestionnaireOption";
import Attestation from "@/components/dashboardPage/school/ASCDashboard/questionnaire/Attestation.vue";
import ReviewQuestionnaire from "@/components/dashboardPage/school/ASCDashboard/questionnaire/ReviewQuestionnaire";
import SchoolsSubmittedQuestionairesTable from "@/components/dashboardPage/school/ASCDashboard/officersComponents/SchoolsSubmittedQuestionairesTable";
import SubmissionReview from "@/components/dashboardPage/school/ASCDashboard/supervisorComponents/SubmissionReview";
import router from "@/router";
import Roles from "@/authorities/roles";
import { mapActions, mapMutations, useStore } from "vuex";

const officerRoles = [
  Roles.smoe_emis_lga_officer,
  Roles.smoe_subeb_lga_officer,
  Roles.smoe_emis_state_officer,
  Roles.smoe_subeb_state_officer,
];

export default {
  name: "AscDataCollection",
  mixins: [dashboardMixinVue, dataCollectionMixin],
  components: {
    QuestionnaireOption,
    Subject,
    Facilities,
    Classroom,
    SchoolStaff,
    SchoolEnrolment,
    SchoolCharacteristics,
    AscDataCollectionSidebar,
    DashboardHeader,
    AscSchoolIntro,
    SchoolIdentification,
    Book,
    FamilyLife,
    TeachersQualification,
    Attestation,
    ReviewQuestionnaire,
    ClipLoader,
    SchoolsSubmittedQuestionairesTable,
    SubmissionReview,
  },
  data() {
    return {
      hasBack: true,
      showSidebar: false,
      questionnaireOption: false,
      staffKey: 0,
      genKey: 0,
      institutionId: null,
    };
  },
  mounted() {
    console.log(this.$store.state.asc.ascTerms);
    if (this.$store.state.asc.ascTerms) {
      this.questionnaireOption = false;
      this.showSidebar = true;
      if (this.isSupervisor) {
        this.showReview();
      } else {
        this.showSchoolIdentification();
      }
    }
    this.setQuestionnaireLoadingStatus(false);
    this.setQAMessage(null);

    if (!this.isSupervisor && !this.isOfficer) {
      this.fetchUserSchool();
    }

    if (this.completedScreens.length > 0) {
      let lastScreen = this.completedScreens.pop();
      this.showSidebar = true;
      resetAll(this.screen);
      this.screen[lastScreen] = true;
    }
  },
  computed: {
    ascTerms() {
      return this.$store.state.asc.ascTerms;
    },
    ownSchool() {
      return this.$store.state.school.ownSchool;
    },
    updateMessage() {
      return this.$store.state.questionnaireService.updateMessage;
    },
    message() {
      if (this.updateMessage !== null) {
        return this.updateMessage.message;
      }
      return "";
    },
    hasMessage() {
      return this.message !== null;
    },
    loading() {
      return this.$store.state.questionnaireService.loading;
    },
    termsLoading() {
      return this.$store.state.asc.loading;
    },
    responseCode() {
      return this.$store.state.questionnaireService.responseCode;
    },
    prevComponent() {
      return this.$store.state.questionnaireStages.previousScreen;
    },
    authUserRole() {
      if (this.$store.getters.loginUser !== null) {
        return this.$store.getters.loginUser.role;
      }
      return null;
    },
    isSupervisor() {
      return this.authUserRole === Roles.supervisor;
    },
    isOfficer() {
      return officerRoles.includes(this.authUserRole);
    },
    completedScreens() {
      return this.$store.state.questionnaireStages.previousScreen;
    },
  },

  methods: {
    ...mapMutations([
      "setPreviousScreen",
      "updatePrevious",
      "setIdentificationDone",
      "setCharacteristicsDone",
      "setEnrolmentDone",
      "setStaffDone",
      "setClassroomDone",
      "setFacilitiesDone",
      "setSubjectDone",
      "setBookDone",
      "setQualificationDone",
      "setFamilyDone",
      "setAttestationDone",
      "setResponseCode",
      "setQAMessage",
      "setQuestionnaireLoadingStatus",
    ]),
    ...mapActions([
      "submitIdentification",
      "submitCharacteristics",
      "fetchMySchool",
    ]),

    fetchUserSchool() {
      this.fetchMySchool(this.$store.getters.loginUser.schoolId);
    },

    setTerms() {
      console.log("Setting terms");
      this.$store
        .dispatch("submitTerms", this.$store.getters.loginUser.schoolId)
        .then(() => {
          this.$notification.open({
            type: this.updateMessage.status,
            message: this.updateMessage.message,
          });
          if (this.updateMessage.status != "error") {
            this.gotoOther();
          }
        });
    },

    showOption() {
      resetAll(this.screen);
      this.questionnaireOption = true;
    },
    showProfile() {
      resetAll(this.screen);
      this.screen.profile = true;
    },
    showAccountSetting() {
      resetAll(this.screen);
      this.screen.accountSetting = true;
    },
    showDashboardSetting() {
      resetAll(this.screen);
      this.screen.dashboardSetting = true;
    },
    editUserProfile() {
      resetAll(this.screen);
      this.screen.showCreateSchool();
    },
    showSchoolDraftTable() {
      resetAll(this.screen);
      this.screen.draftTable = true;
    },
    gotoNMEC() {
      router.push("/nmecDashboard");
    },
    gotoOther() {
      this.questionnaireOption = false;
      this.showSidebar = true;
      if (this.isSupervisor) {
        this.showReview();
      } else {
        this.showSchoolIdentification();
      }
    },

    async saveIdentification(schoolInfo) {
      await this.submitIdentification(schoolInfo).then(() => {
        this.$notification.open({
          type: this.updateMessage.status,
          message: "Success Message",
          description: this.message,
        });
        if (this.responseCode === 204) {
          this.setIdentificationDone(true);
          this.setPreviousScreen("schoolIdentification");
          this.showCharacteristics();
        }
      });
    },

    async saveCharacteristics(characteristicsInfo) {
      await this.submitCharacteristics(characteristicsInfo).then(() => {
        this.$notification.open({
          type: this.updateMessage.status,
          message: "Response Message",
          description: this.message,
        });
        console.log(this.message);
        if (this.responseCode === 201) {
          this.setCharacteristicsDone(true);
          this.setPreviousScreen("characteristics");
          this.showEnrolment();
        }
      });
    },

    saveEnrolment() {
      this.setEnrolmentDone(true);
      this.setPreviousScreen("enrolment");
      this.showStaff();
    },

    saveStaff() {
      this.setStaffDone(true);
      this.setPreviousScreen("staff");
      this.showClassroom();
    },

    saveClassroom() {
      this.setClassroomDone(true);
      this.setPreviousScreen("classroom");
      this.showFacilities();
    },

    saveFacilities() {
      this.setFacilitiesDone(true);
      this.setPreviousScreen("facilities");
      this.showSubject();
    },

    saveSubject() {
      this.setSubjectDone(true);
      this.setPreviousScreen("subject");
      this.showBook();
    },

    saveBook() {
      this.setBookDone(true);
      this.setPreviousScreen("subject");
      this.showQualification();
    },

    saveQualification() {
      this.setQualificationDone(true);
      this.setPreviousScreen("qualification");
      this.showFamily();
    },

    showSubmissionReview(institutionId) {
      resetAll(this.screen);
      this.institutionId = institutionId;
      this.screen.submissionReview = true;
    },

    handleGoBack() {
      if (this.prevComponent.length > 0) {
        const prev = this.prevComponent.pop();
        resetAll(this.screen);
        this.screen[prev] = true;
      } else {
        this.goBack();
      }
    },
  },
};
</script>

<style lang="scss">
@import "../../style/variable";

.active {
  color: $blue-dm;
}
</style>
