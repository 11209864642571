import axios from "axios";

const initState = {
  schools: null,
  ownSchool: null,
  pagedSchools: null,
  schoolClassrooms: null,
  schoolTeachersQualification: null,
  schoolType: null,
  loading: false,
  numberOfRows: 12,
  numRowsClassrooms: 12,
  numRowsQualifications: 12,
  lastNumOfRows: null,
  lastNumRowsClassrooms: null,
  lastNumRowsQualifications: null,
  activePage: 0,
  resCode: null,
  schoolMessage: null,
};

const state = initState;

const getters = {
  getOwnSchool: (state) => state.ownSchool,
  getSchoolType: (state) => state.schoolType,
};

const actions = {
  async getUserSchool({ commit, state }, schoolCode) {
    await axios
      .get(`schools/me/${schoolCode}`, { timeout: 10000 })
      .then((res) => {
        console.log(res.data);
        commit("setOwnSchool", res.data);
      });
  },

  async fetchSchools({ commit, state }) {
    if (state.schools === null) {
      commit("setLoadingStatus", true);
      await axios
        .get(`schools/school-orgs`, { timeout: 10000 })
        .then((res) => {
          commit("setSchools", res.data);
          commit("setLoadingStatus", false);
        })
        .catch((err) => {
          commit("setLoadingStatus", false);
          commit("setSchoolMessage", err.response.data.error_description);
        });
    }
  },

  async createSchool({ commit, state }, requestBody) {
    commit("setLoadingStatus", true);
    let resp = "";
    await axios
      .post(`schools/schools/v1`, requestBody, { timeout: 10000 })
      .then((res) => {
        commit("setLoadingStatus", false);
        commit("setSchoolMessage", res.data.message);
        commit("setResCode", res.status);
        console.log();
        console.log(res, ">>>>>>");
      })
      .catch((err) => {
        commit("setLoadingStatus", false);
        console.log(err, "<<<<<<<", resp);

        commit("setResCode", err);
        commit("setSchoolMessage", err);
      });
  },

  async updateSchoolInfo({ commit, state }, requestBody) {
    const { id, body } = requestBody;
    commit("setLoadingStatus", true);
    await axios
      .patch(`schools/schools/${id}`, body, { timeout: 10000 })
      .then((res) => {
        commit("setLoadingStatus", false);
        commit("setSchoolMessage", res.data.message);
        commit("setResCode", res.status);
      })
      .catch((err) => {
        commit("setLoadingStatus", false);
        commit("setSchoolMessage", err.response.data.error_description);
      });
  },

  async fetchMySchool({ commit, state }, id) {
    console.log(id, "<<<<<<<<<<<");

    if (state.ownSchool === null || state.ownSchool == []) {
      commit("setLoadingStatus", true);
      await axios
        .get(`schools/schools/${id}`, { timeout: 10000 })
        .then((res) => {
          commit("setOwnSchool", res.data);
          commit("setResCode", res.status);
          console.log(res.status);
          commit("setLoadingStatus", false);
        })
        .catch((err) => {
          commit("setLoadingStatus", false);
          console.log(err);
          commit("setSchoolMessage", err.response.data.error_description);
        });
    } else {
      console.log("Couldn't fetch my school");
    }
  },

  async fetchPagedSchools({ commit, state }, nextPage) {
    // if (state.pagedSchools === null || state.numberOfRows !== state.lastNumOfRows || nextPage !== undefined) {
    if (state.numberOfRows !== state.lastNumOfRows || nextPage !== undefined) {
      commit("setLastNumOfRows", state.numberOfRows);
      commit("setLoadingStatus", true);
      let url = "";
      if (nextPage !== undefined) {
        commit("setActivePage", nextPage);
        url = `schools/schools-paged?size=${state.numberOfRows}&&page=${nextPage}`;
      } else {
        url = `schools/schools-paged?size=${state.numberOfRows}`;
      }
      await axios
        .get(url, { timeout: 10000 })
        .then((res) => {
          commit("setLoadingStatus", false);
          commit("setPagedSchools", res.data);
        })
        .catch(() => {
          commit("setLoadingStatus", false);
        });
    } else {
      console.log("Couldn't fetch");
    }
  },

  async toggleSchoolActiveStatus({ commit, state, dispatch }, schoolId) {
    commit("setLoadingStatus", true);
    await axios
      .patch(`schools/schools-activate/${schoolId}`, { timeout: 10000 })
      .then((res) => {
        commit("setLoadingStatus", false);
        if (res.data.status_code === 204) {
          state.pagedSchools = null;
          commit("setSchoolMessage", res.data.message);
          dispatch("fetchPagedSchools", state.activePage);
        }
      })
      .catch((err) => {
        commit("setLoadingStatus", false);
        commit("setSchoolMessage", err.response.data.error_description);
      });
  },

  async fetchSchoolClassrooms({ commit }, body) {
    const { id, nextPage } = body;
    if (
      state.schoolClassrooms === null ||
      state.numRowsClassrooms !== state.lastNumRowsClassrooms ||
      nextPage !== undefined
    ) {
      commit("setLoadingStatus", true);
      await axios
        .get(`f/classroom-info-paged/${id}`, { timeout: 10000 })
        .then((res) => {
          commit("setLoadingStatus", false);
          commit("setSchoolClassrooms", res.data);
        })
        .catch((err) => {
          commit("setLoadingStatus", false);
          commit("setSchoolMessage", err.response.data.error_description);
        });
    } else {
      console.log("Not fetching classroom");
    }
  },

  async fetchSchoolTeachersQualification({ state, commit }, requestObject) {
    const { id, nextPage } = requestObject;
    if (
      state.schoolClassrooms === null ||
      state.numRowsQualifications !== state.lastNumRowsClassrooms ||
      nextPage !== undefined
    ) {
      const url =
        nextPage === undefined
          ? `auth/qualifications-paged/school/${id}?size=${state.numRowsQualifications}`
          : `auth/qualifications-paged/school/${id}?size=${state.numRowsQualifications}&&page=${nextPage}`;
      commit("setLastNumRowsQualifications", state.numRowsQualifications);
      commit("setLoadingStatus", true);
      await axios
        .get(url, { timeout: 10000 })
        .then((res) => {
          commit("setLoadingStatus", false);
          commit("setSchoolTeachersQualification", res.data);
        })
        .catch((err) => {
          commit("setLoadingStatus", false);
          if (err.response !== undefined) {
            const message =
              err.response.data.error_description !== undefined
                ? err.response.data.error_description
                : err.response.data.body !== undefined
                ? err.response.data.body
                : err.response.data.message;
            commit("setSchoolMessage", message);
          } else {
            commit("setSchoolMessage", "Timeout");
          }
        });
    } else {
      console.log("Not fetching classroom");
    }
  },
};

const mutations = {
  setOwnSchool(state, school) {
    state.ownSchool = school;
  },
  setSchoolType(state, type) {
    state.schoolType = type;
  },
  setLoadingStatus(state, status) {
    state.loading = status;
  },
  setSchools(state, value) {
    state.schools = value;
  },
  setPagedSchools(state, value) {
    state.pagedSchools = value;
  },
  setNumOfRows(state, value) {
    if (value !== state.lastNumOfRows) {
      state.numberOfRows = value;
    }
  },
  setNumRowsClassrooms(state, value) {
    if (value !== state.lastNumRowsClassrooms) {
      state.numRowsClassrooms = value;
    }
  },
  setNumRowsQualifications(state, value) {
    if (value !== state.lastNumRowsQualifications) {
      state.numRowsQualifications = value;
    }
  },
  setLastNumOfRows(state, value) {
    state.lastNumOfRows = value;
  },
  setLastNumRowsClassrooms(state, value) {
    state.lastNumRowsClassrooms = value;
  },
  setLastNumRowsQualifications(state, value) {
    state.lastNumRowsClassrooms = value;
  },
  setActivePage(state, value) {
    state.activePage = value;
  },
  setSchoolMessage(state, value) {
    state.schoolMessage = value;
  },
  setResCode(state, value) {
    state.resCode = value;
  },
  setSchoolClassrooms(state, value) {
    state.schoolClassrooms = value;
  },
  setSchoolTeachersQualification(state, value) {
    state.schoolTeachersQualification = value;
  },
  resetSchoolState(state) {
    Object.assign(state, initState);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
