import axios from "axios";

const initState = {
  lgaValidationList: null,
  loading: false,
};

const state = initState;

const getters = {
  getLgaValidationList: (state) => state.lgaValidationList,
};

const actions = {
  async getLgaValidationList({ commit, state }, lga) {
    commit("setLoadingStatus", true);
    await axios.get(`schools/schools/q?lga=${lga}`).then((res) => {
      commit("setLoadingStatus", false);
      commit("setLgaValidation", res.data);
    });
  },

  //   async fetchSchools({ commit, state }) {
  //     if (state.schools === null) {
  //       commit("setLoadingStatus", true);
  //       await axios
  //         .get(`schools/school-orgs`, { timeout: 10000 })
  //         .then((res) => {
  //           commit("setSchools", res.data);
  //           commit("setLoadingStatus", false);
  //         })
  //         .catch((err) => {
  //           commit("setLoadingStatus", false);
  //           commit("setSchoolMessage", err.response.data.error_description);
  //         });
  //     }
  //   }
};

const mutations = {
  setLgaValidation(state, lgaList) {
    state.lgaValidationList = lgaList;
  },
  setLoadingStatus(state, status) {
    state.loading = status;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
