<template>
  <div>
    <!-- <div class="d-flex flex-wrap mt-3">
      <div class="col-12 col-lg-6 mb-3">
        <div class="percentage-container bg-white radius-full me-lg-2">
          <Progress :stroke-color="{'0%': '#C901A1', '100%': '#C901A1'}" :strokeWidth="10" :width="80"
                    :height="80" type="circle" :percent="65"/>
          <div class="d-flex flex-column ms-3">
            <span class="font-sm fw-normal">Percentage of overage pupils</span>
            <span class="d-flex align-items-end">
              <span class="font-big fw-bold">65</span>
              <span class="font-tiny fw-light ms-3 mb-2">{{ decrease }}</span>
            </span>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6 mb-3">
        <div class="percentage-container bg-white radius-full ms-2">
          <Progress :stroke-color="{'0%': '#00CCF2', '100%': '#00CCF2'}" :strokeWidth="10" :width="80"
                    :height="80" type="circle" :percent="8"/>
          <div class="d-flex flex-column ms-3">
            <span class="font-sm fw-normal">Percentage of underage pupils</span>
            <span class="d-flex align-items-end">
              <span class="font-big fw-bold">8</span>
              <span class="font-tiny fw-light ms-3 mb-2">{{ increase }}</span>
            </span>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6 mb-3">
        <div class="percentage-container bg-white radius-full me-lg-2">
          <Progress :stroke-color="{'0%': '#017EBE', '100%': '#017EBE'}" :strokeWidth="10" :width="80"
                    :height="80" type="circle" :percent="15"/>
          <div class="d-flex flex-column ms-3">
            <span class="font-sm fw-normal">Percentage of overage pupils</span>
            <span class="d-flex align-items-end">
              <span class="font-big fw-bold">15</span>
              <span class="font-tiny fw-light ms-3 mb-2">{{ decrease }}</span>
            </span>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6 mb-3">
        <div class="percentage-container bg-white radius-full ms-2">
          <Progress :stroke-color="{'0%': '#F98810', '100%': '#F98810'}" :strokeWidth="10" :width="80"
                    :height="80" type="circle" :percent="72"/>
          <div class="d-flex flex-column ms-3">
            <span class="font-sm fw-normal">Percentage of underage pupils</span>
            <span class="d-flex align-items-end">
              <span class="font-big fw-bold">72</span>
              <span class="font-tiny fw-light ms-3 mb-2">{{ increase }}</span>
            </span>
          </div>
        </div>
      </div>
    </div> -->

    <classroom-chart :key="classroomKey" @reload="classroomKey++" />

    <toilet-chart :key="toiletKey" @reload="toiletKey++" />

    <water-infrastructure-chart :key="waterKey" @reload="waterKey++" />

    <pupil-teacher-ratio-chart
      :key="pupilTeacherKey"
      @reload="pupilTeacherKey++"
    />

    <real-quality-chart :key="realQualityKey" @reload="realQualityKey++" />

    <teachers-chart :key="teachersKey" @reload="teachersKey++" />

    <total-schools-chart :key="totalSchoolsKey" @reload="totalSchoolsKey++" />

    <number-of-school-with-chart
      :key="SchoolsWithKey"
      @reload="SchoolsWithKey++"
    />
  </div>
</template>

<script>
import ClassroomChart from "@/components/dashboardPage/main/quality/charts/ClassroomChart";
import ToiletChart from "@/components/dashboardPage/main/quality/charts/ToiletChart";
import WaterInfrastructureChart from "@/components/dashboardPage/main/quality/charts/WaterInfrastructureChart";
import PupilTeacherRatioChart from "@/components/dashboardPage/main/quality/charts/PupilTeacherRatioChart";
import RealQualityChart from "@/components/dashboardPage/main/quality/charts/RealQualityChart";
import TeachersChart from "@/components/dashboardPage/main/quality/charts/TeachersChart";
import TotalSchoolsChart from "@/components/dashboardPage/main/quality/charts/TotalSchoolsChart";
import NumberOfSchoolWithChart from "@/components/dashboardPage/main/quality/charts/NumberOfSchoolWithChart";
import { Progress } from "ant-design-vue";

export default {
  name: "QualityChartsByLevel",
  props: ["level"],
  components: {
    Progress,
    ClassroomChart,
    ToiletChart,
    WaterInfrastructureChart,
    PupilTeacherRatioChart,
    RealQualityChart,
    TeachersChart,
    TotalSchoolsChart,
    NumberOfSchoolWithChart,
  },
  setup() {
    const infrastructures = [
      {
        name: "Building",
        condition: "Good",
      },
      {
        name: "Heating",
        condition: "Good",
      },
      {
        name: "Electricity",
        condition: "Poor",
      },
      {
        name: "Fence",
        condition: "Poor",
      },
    ];

    return {
      option: "",
      infrastructures,
    };
  },
  data() {
    return {
      underage: "65%",
      overage: "25%",
      increase: "+3% this term",
      decrease: "-3% this term",
      isActive: "pre-primary",

      classroomKey: 0,
      toiletKey: 0,
      waterKey: 0,
      pupilTeacherKey: 0,
      realQualityKey: 0,
      teachersKey: 0,
      totalSchoolsKey: 0,
      SchoolsWithKey: 0,
    };
  },
};
</script>

<style scoped>
.bordered-header {
  border-bottom: 1px solid #efefef;
}
.bordered-header-active {
  border-bottom: 1px solid #0000ff !important;
}
.is-good {
  background: hsla(124, 98%, 35%, 0.09) 0% 0% no-repeat padding-box;
  border-radius: 6px;
  color: green;
}
.is-poor {
  background: hsla(16, 98%, 35%, 0.09) 0% 0% no-repeat padding-box;
  border-radius: 6px;
  color: #b33102;
}
.facility-container {
  max-height: 340px;
  overflow-y: scroll;
}
.full-height {
  height: 80%;
}

.title-height {
  height: 20%;
}
</style>
