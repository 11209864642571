<template>
  <div class="d-flex flex-column">
    <div v-if="loading" class="overlay">
      <clip-loader class="loading"></clip-loader>
    </div>
    <div class="d-flex justify-content-between mt-5">
      <p class="font-md fw-bold">{{ lga }}</p>
      <div class="dropdown">
        <button
          class="btn btn-primary dropdown-toggle"
          type="button"
          id="dropdownMenuButton1"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <strong> Bulk Actions</strong>
          <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.4"
              d="M18.593 9.07486C19.0376 9.40238 19.1326 10.0284 18.8051 10.4731C18.5507 10.8185 18.2963 11.1468 18.0731 11.4328C17.6276 12.0036 17.0143 12.7682 16.3479 13.5356C15.6859 14.2981 14.9518 15.0864 14.2666 15.6919C13.9251 15.9936 13.5721 16.2711 13.2279 16.4786C12.9112 16.6695 12.476 16.88 11.9999 16.88C11.5238 16.88 11.0885 16.6695 10.7718 16.4786C10.4276 16.2711 10.0747 15.9936 9.7332 15.6919C9.04791 15.0864 8.31387 14.2981 7.65183 13.5356C6.98548 12.7682 6.37216 12.0036 5.92664 11.4328C5.70347 11.1468 5.44902 10.8185 5.19463 10.4731C4.86712 10.0284 4.96211 9.40238 5.4068 9.07486C5.58556 8.94321 5.79362 8.87984 5.99982 8.88001L11.9999 8.88001L17.9999 8.88001C18.2061 8.87984 18.4142 8.94321 18.593 9.07486Z"
              fill="white"
            />
            <path
              d="M18.593 9.07486C19.0376 9.40238 19.1326 10.0284 18.8051 10.4731C18.5507 10.8185 18.2963 11.1468 18.0731 11.4328C17.6276 12.0036 17.0143 12.7682 16.3479 13.5356C15.6859 14.2981 14.9518 15.0864 14.2666 15.6919C13.9251 15.9936 13.5721 16.2711 13.2279 16.4786C12.9112 16.6695 12.476 16.88 11.9999 16.88C11.5238 16.88 11.0885 16.6695 10.7718 16.4786C10.4276 16.2711 10.0747 15.9936 9.7332 15.6919C9.15076 15.1773 8.53312 14.5306 7.95439 13.88L13 8.88001L17.9999 8.88001C18.2061 8.87984 18.4142 8.94321 18.593 9.07486Z"
              fill="white"
            />
          </svg>
        </button>
        <ul
          class="dropdown-menu bg-secondary"
          aria-labelledby="dropdownMenuButton1"
        >
          <li>
            <button
              class="dropdown-item text-white"
              @click="approvelgaValidation"
            >
              Approve
            </button>
          </li>
          <hr class="mt-0 mb-0" />
          <li><a class="dropdown-item text-white" href="#">Decline</a></li>
        </ul>
      </div>
    </div>
    <div class="bg-white radius-full shadow-sm mb-5">
      <table class="table table-hover font-sm" aria-label="Users table">
        <thead>
          <tr>
            <th class="align-middle py-3 ps-4" scope="col">
              <div class="d-flex flex-nowrap align-items-center">
                <input
                  type="checkbox"
                  id="selectAll"
                  @change="selectAllRows($event)"
                  :checked="allRowsSelected"
                />
              </div>
            </th>
            <th class="align-middle py-3 ps-4" scope="col">
              <div class="d-flex flex-nowrap align-items-center">
                <span>School Name</span>
              </div>
            </th>
            <th class="align-middle py-3" scope="col">
              <div class="d-flex flex-nowrap align-items-center">
                <span>Classification</span>
              </div>
            </th>
            <th class="align-middle py-3" scope="col">
              <div class="d-flex flex-nowrap align-items-center">
                <span>Submission date</span>
              </div>
            </th>
            <th class="align-middle py-3" scope="col">
              <div class="d-flex flex-nowrap align-items-center">
                <span>Date updated</span>
              </div>
            </th>
            <th class="align-middle py-3" scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="pointer-no-bg"
            v-for="(school, index) in lgaValidationList"
            :key="index"
          >
            <td class="align-middle py-3 ps-4">
              <input
                type="checkbox"
                :value="school"
                :checked="selectedRows.includes(school)"
                @change="toggleRowSelection(school)"
              />
            </td>
            <td class="align-middle py-3 ps-4">
              {{ school.schoolName }}
            </td>
            <td class="py-3">{{ school.classification }}</td>
            <td class="py-3">{{ school.createdAt }}</td>
            <td class="py-3">{{ school.updatedAt }}</td>
            <td class="text-center dropend pe-2 py-3">
              <span class="text-primary bold"> <strong>View</strong> </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import { mapActions, mapMutations, useStore } from "vuex";

export default {
  name: "LGAValidation",
  components: { ClipLoader },
  setup() {
    const store = useStore();
    const lgaValidationList = computed(
      () => store.getters.getLgaValidationList
    ).value;
    const userMe = computed(() => store.getters.loginUser).value;
    const loading = computed(() => store.state.validation.loading).value;
    console.log(userMe, "??????");
    console.log(lgaValidationList, "<<<<<<");

    onMounted(() => {
      store.dispatch("getLgaValidationList", userMe.lga);
    });
    const schools = [
      {
        id: 1,
        name: "John Quincy Adams Middle School",
        subDate: "12-12-2001",
        dateUdpated: "12-2-2002",
        status: "In progress",
      },
      {
        id: 2,
        name: "Demonstration Secondary School",
        subDate: "12-12-2001",
        dateUdpated: "12-2-2002",
        status: "In progress",
      },
      {
        id: 3,
        name: "Olivet Heights International School",
        subDate: "12-12-2001",
        dateUdpated: "12-2-2002",
        status: "In progress",
      },
      {
        id: 4,
        name: "Skykrest Junior and Primary School",
        subDate: "12-12-2001",
        dateUdpated: "12-2-2002",
        status: "In progress",
      },
    ];
    // State to track selected rows
    const selectedRows = ref([]);

    // Computed property to determine if all rows are selected
    const allRowsSelected = computed(() => {
      return selectedRows.value.length === lgaValidationList.length;
    });

    // Select or deselect all rows
    const selectAllRows = (event) => {
      if (event.target.checked) {
        selectedRows.value = lgaValidationList.map((school) => school);
      } else {
        selectedRows.value = [];
      }
    };

    // Handle selection for individual checkboxes
    const toggleRowSelection = (id) => {
      if (selectedRows.value.includes(id)) {
        selectedRows.value = selectedRows.value.filter((rowId) => rowId !== id); // Deselect
      } else {
        selectedRows.value.push(id); // Select
      }
    };

    const approvelgaValidation = () => {
      console.log(selectedRows.value);
    };
    return {
      lga: userMe.lga,
      schools,
      lgaValidationList,
      selectedRows,
      selectAllRows,
      toggleRowSelection,
      allRowsSelected,
      approvelgaValidation,
      loading,
    };
  },
};
</script>
