export const emisconfig = {
  stateName: "ADAMAWA",
  // baseURL: "https://yobe.emis.gov.ng/",
  baseURL: "https://adamawa.emis.gov.ng/",
  stateCode: 1,
  // 7=> BORNO
  // 1 => ADAMAWA
  // 35 => YOBE
  stateLogo: "",
};
