<template>
  <div>
    <div
      class="chart-container bg-white d-flex radius-full pt-4 px-4 mt-4 mb-2"
    >
      <canvas
        v-if="hasData"
        class="charts"
        id="explore-dynamic-chart"
        height="300"
      ></canvas>
      <div
        v-else
        class="w-100 d-flex justify-content-center align-items-center"
      >
        <span class="font-md fw-medium text-info"
          >Please select options from the sidebar to compute chart.</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import Chart from "chart.js/auto";
import { useStore } from "vuex";

export default {
  name: "ExploreDynamicChart",
  setup() {
    const store = useStore();
    const chartData = ref({});
    const xAxisLabels = ref([]);
    let sets = new Set();
    const exploreData = computed(() => store.state.explore.chartData);

    const hasData = computed(() => exploreData.value !== null);

    if (exploreData.value !== null) {
      const proxyData = Object.values(exploreData.value);
      xAxisLabels.value = proxyData.map((data) => Object.keys(data).join());
      const dataSets = proxyData.map((data) => Object.values(data)).flat();
      const groupKeys = dataSets[0];

      if (dataSets.length > 1) {
        Object.keys(groupKeys).forEach((element) => {
          const r = Math.floor(Math.random() * 100) + 75;
          const g = Math.floor(Math.random() * 155) + 100;
          const b = Math.floor(Math.random() * 55) + 200;
          const color = `rgba(${r}, ${g}, ${b}, 0.7)`;

          let someArray = dataSets.map((item) => item[element]);
          const set = {
            label: element,
            categoryPercentage: 0.8,
            data: someArray,
            backgroundColor: color,
          };
          sets.add(set);
        });
      }
    }

    if (sets.size > 0) {
      chartData.value = {
        type: "bar",
        data: {
          labels: xAxisLabels,
          datasets: Array.from(sets),
        },
        maintainAspectRatio: false,
        options: {
          responsive: true,
          plugins: {
            legend: {
              display: true,
              position: "bottom",
            },
          },
        },
      };
    }

    onMounted(() => {
      const dynamicChart = document.getElementById("explore-dynamic-chart");
      new Chart(dynamicChart, chartData.value);
    });

    return {
      hasData,
    };
  },
};
</script>

<style scoped>
.chart-container {
  height: 440px !important;
}
</style>
