import axios from "axios";
const authInitStates = {
  token: null,
  user: null,
  numberOfRows: 12,
  lastNumberOfRows: null,
  currentlyActivePage: 0,
  users: [],
  schoolStaff: null,
  staffSearchResult: null,
  email: null,
  message: null,
  loading: false,
  host: "http://ec2-3-80-156-41.compute-1.amazonaws.com",
};

const state = authInitStates;

const getters = {
  isAuthenticated: (state) => !!state.token,
  loginUser: (state) => state.user,
  loadingStatus: (state) => state.loading,
  getMessage: (state) => state.message,
  getUserById: (state) => (id) => {
    return state.users.find((user) => user.id === id);
  },
};

const actions = {
  async login({ commit, state }, user) {
    commit("setLoadingStatus", true);
    await axios
      .post(`auth/login`, user, { timeout: 10000 })
      .then((res) => {
        commit("setLoadingStatus", false);
        commit("setAuthUserEmail", user.email);
        if (res.data.body !== null) {
          commit("setToken", res.data.body.access_token);
          commit("setMessage", {
            status: "success",
            message: "Login Successfull",
          });
        } else if (res.data.statusCodeValue === 401) {
          commit("setMessage", {
            status: res.data.statusCodeValue,
            message: "Invalid login details",
          });
        }
      })
      .catch((err) => {
        commit("setMessage", {
          status: "error",
          message: err.message,
        });
        commit("setLoadingStatus", false);
      });
  },

  async getAuthUser({ commit, state }) {
    if (state.user === null) {
      await axios
        .get(`auth/users/my-details`, { timeout: 10000 })
        .then((res) => {
          commit("setLoadingStatus", false);
          console.log(res.data, "authur");
          commit("setAuthUser", res.data);
        })
        .catch(() => commit("setLoadingStatus", false));
    }
  },

  async registerUser({ commit, state }, user) {
    commit("setLoadingStatus", true);
    await axios
      .post(`auth/users`, user, { timeout: 10000 })
      .then((res) => {
        commit("setLoadingStatus", false);
        if (res.data.status_code === 201) {
          commit("setMessage", `${res.data.message}`);
        } else {
          console.log(res);
        }
      })
      .catch((err) => {
        commit("setLoadingStatus", false);
        if (err.response.status === 500) {
          commit("setMessage", err.response.data.description);
        }
        commit("setMessage", err.response.data.error_description);
      });
  },

  async updateUser({ commit, state }, body) {
    let { id, user } = body;
    commit("setLoadingStatus", true);
    await axios
      .patch(`auth/users/${id}`, user, { timeout: 10000 })
      .then((res) => {
        commit("setLoadingStatus", false);
        if (res.data.status_code === 204) {
          commit("setMessage", res.data.message);
        }
      })
      .catch((err) => {
        commit("setLoadingStatus", false);
        commit("setMessage", err.response.data.error_description);
      });
  },

  async fetchUsers({ commit, state }, nextPage) {
    if (
      state.users.length === 0 ||
      state.numberOfRows !== state.lastNumberOfRows ||
      nextPage !== undefined
    ) {
      commit("setLastNumberOfRows", state.numberOfRows);
      commit("setLoadingStatus", true);
      let url = "";
      if (nextPage !== undefined) {
        commit("setCurrentlyActivePage", nextPage);
        url = `auth/users-paged?size=${state.numberOfRows}&&page=${nextPage}`;
      } else {
        url = `auth/users-paged?size=${state.numberOfRows}`;
      }
      await axios
        .get(url, { timeout: 10000 })
        .then((res) => {
          commit("setLoadingStatus", false);
          commit("setUsers", res.data);
        })
        .catch((err) => {
          commit("setLoadingStatus", false);
          commit("setMessage", err.message);
        });
    }
  },

  async updateRoleForUser({ commit, state, dispatch }, requestObject) {
    commit("setLoadingStatus", true);
    let { role, user } = requestObject;
    const body = { role: role };

    await axios
      .patch(`auth/users-role/${user.id}`, body, {
        timeout: 10000,
      })
      .then((res) => {
        commit("setLoadingStatus", false);
        if (res.data.status_code === 204) {
          state.users = [];
          dispatch("fetchUsers", state.currentlyActivePage);
          commit("setMessage", res.data.message);
        }
      })
      .catch((err) => {
        commit("setLoadingStatus", false);
        if (err) console.log("Unable to fetch data");
      });
  },

  async activateDeactivate({ commit, state, dispatch }, userId) {
    commit("setLoadingStatus", true);
    await axios
      .get(`auth/users-activate/${userId}`, { timeout: 10000 })
      .then((res) => {
        commit("setLoadingStatus", false);
        if (res.data.status_code === 204) {
          state.users = [];
          dispatch("fetchUsers", state.currentlyActivePage);
        }
      })
      .catch((err) => {
        commit("setLoadingStatus", false);
        commit("setMessage", err.response.data.error_description);
      });
  },

  async deleteUser({ commit }, userId) {
    commit("setLoadingStatus", true);
    await axios
      .delete(`auth/users/${userId}`, { timeout: 10000 })
      .then((res) => {
        console.log(res.data);
        if (res.data.statusCodeValue === 200) {
          commit("setLoadingStatus", false);
          commit("setMessage", "Successful");
        }
      })
      .catch(() => {
        commit("setLoadingStatus", false);
      });
  },

  async fetchSchoolStaff({ state, commit }, schoolId) {
    if (
      state.schoolStaff === null ||
      state.schoolStaff.content.count === undefined
    ) {
      commit("setLoadingStatus", true);
      await axios
        .get(`auth/staff/school/${schoolId}/paged`, {
          timeout: 10000,
        })
        .then((res) => {
          commit("setLoadingStatus", false);
          console.log(res.data, "????>>>>>>");
          commit("setSchoolStaff", res.data);
        })
        .catch((err) => {
          commit("setLoadingStatus", false);
          console.log(err.response);
        });
    } else {
      console.log("Can't fetch staffs");
    }
  },

  async findStaffByFileNumber({ commit }, fileNumber) {
    commit("setLoadingStatus", true);
    await axios
      .get(`auth/staff-file-number/${fileNumber}`, {
        timeout: 10000,
      })
      .then((res) => {
        commit("setLoadingStatus", false);
        commit("setStaffSearchResult", res.data);
        commit("setMessage", "Found staff with file number: " + fileNumber);
      })
      .catch((err) => {
        commit("setLoadingStatus", false);
        commit("setMessage", err.response.data.error_description);
      });
  },
};

const mutations = {
  setToken(state, token) {
    state.token = token;
  },

  setAuthUserEmail(state, email) {
    state.email = email;
  },

  setUsers(state, users) {
    state.users = users;
  },

  setAuthUser(state, user) {
    state.user = user;
  },

  setMessage(state, message) {
    console.log(message);
    state.message = message;
  },

  setLoadingStatus(state, value) {
    state.loading = value;
  },

  setNumberOfRows(state, value) {
    if (value !== state.lastNumberOfRows) {
      state.numberOfRows = value;
    }
  },

  setLastNumberOfRows(state, value) {
    state.lastNumberOfRows = value;
  },

  setCurrentlyActivePage(state, value) {
    state.currentlyActivePage = value;
  },

  setSchoolStaff(state, value) {
    state.schoolStaff = value;
  },

  setStaffSearchResult(state, value) {
    state.staffSearchResult = value;
  },

  resetAuthState(state) {
    Object.assign(state, authInitStates);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
