<template>
  <div class="d-flex flex-wrap justify-content-center mt-5">
    <div
      v-if="ability.can('read', 'planning')"
      class="d-flex flex-column align-items-center justify-content-center asc-custom-card col-4"
      @click="$emit('showASCPlanning')"
    >
      <span class="number-display"> 1 </span>
      <span class="font-sm mt-3">Planning</span>
    </div>

    <div v-if="ability.cannot('create', 'ASC')" class="col-4 px-2">
      <div
        class="d-flex flex-column align-items-center justify-content-center asc-custom-card"
        @click="$emit('moecollectionReviewPrompt')"
      >
        <span class="number-display"> 2 </span>
        <span class="font-sm mt-3">Data collection</span>
      </div>
    </div>

    <!-- <router-link v-else class="col-4 px-2" to="/ascDashboard/dataCollection"> -->

    <div
      v-else
      class="d-flex flex-column align-items-center justify-content-center asc-custom-card col-4"
      @click="$emit('collectionPrompt')"
    >
      <span class="number-display"> 2 </span>
      <span class="font-sm mt-3 text-black">Data collection</span>
    </div>
    <!-- <div
        class="
          d-flex
          flex-column
          align-items-center
          justify-content-center
          asc-custom-card"
          @click="$emit('collectionPrompt')"
      >
        <span class="number-display">
          2
        </span>
        <span class="font-sm mt-3 text-black">Data collection</span>
      </div> -->
    <!-- </router-link> -->

    <div
      v-if="ability.can('read', 'lga validation')"
      class="d-flex flex-column align-items-center justify-content-center asc-custom-card col-4"
      @click="$emit('showLGAValidation')"
    >
      <span class="number-display"> 3 </span>
      <span class="font-sm mt-3">LGA validation</span>
    </div>
  </div>

  <div class="d-flex justify-content-center px-0 mt-5">
    <div
      v-if="ability.can('read', 'state validation')"
      class="d-flex flex-column align-items-center justify-content-center asc-custom-card col-4 me-2"
      @click="$emit('showStateValidation')"
    >
      <span class="number-display"> 4 </span>
      <span class="font-sm mt-3">State Validation</span>
    </div>
    <div
      v-if="ability.can('read', 'report generation')"
      class="d-flex flex-column align-items-center justify-content-center asc-custom-card col-4 me-2"
    >
      <span class="number-display"> 5 </span>
      <span class="font-sm mt-3">Report generation</span>
    </div>
  </div>
</template>

<script>
import { computed } from "@vue/runtime-core";
import defineAbilityFor from "@/authorities";
import { useStore } from "vuex";

export default {
  name: "AscManagement",
  emits: [
    "showASCPlanning",
    "showLGAValidation",
    "showStateValidation",
    "collectionPrompt",
  ],
  setup() {
    const store = useStore();
    const userMe = computed(() => store.getters.loginUser).value;

    const ability = defineAbilityFor(userMe);

    return {
      ability,
    };
  },
};
</script>

<style>
/*.action-card {*/
/*  height: 11rem;*/
/*  background: #fff;*/
/*  cursor: pointer;*/
/*  border: none;*/
/*  border-radius: 0.5rem;*/
/*  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;*/
/*}*/

.number-display {
  font-size: 50px;
  color: gray;
  text-decoration: none;
}
a {
  text-decoration: none;
}
</style>
